import React, { PropsWithChildren } from "react"

interface BadgeVariant {
  reward: string
  green: string
  orange: string
  red: string
  blue: string
  default: string
  bonusQuestion: string
  darkGreen: string
}

interface BadgeProps extends PropsWithChildren {
  variant?: keyof BadgeVariant
  className?: string
  icon?: React.ReactNode
  inverse?: boolean
  glow?: boolean
}

const variants: BadgeVariant = {
  reward: "bg-opacity-16 bg-gold text-gold",
  green: "bg-opacity-16 bg-[#7BE571] text-[#7BE571]",
  darkGreen: "bg-opacity-16 bg-[#0ECFAE29] text-success-500",
  orange: "bg-opacity-16 bg-[#EF7720] text-[#EF7720]",
  red: "bg-opacity-16 bg-error-400 text-error-400",
  blue: "bg-opacity-16 bg-[#0E2845] text-[#2CA6FF]",
  default: "text-primary-300 bg-[#2B293E]",
  bonusQuestion: "bg-[#FEA566] text-[#8C532B]",
}

const Badge: React.FC<BadgeProps> = ({ children, className, variant = "default", icon, glow, inverse = false }) => {
  return (
    <div
      className={`${className} ${inverse && "bg-[#151829] bg-opacity-90 "} ${
        glow && "shadow-[0px_0px_50px_0px_rgba(232,171,67,0.50)]"
      } h-fit w-fit overflow-hidden rounded-full backdrop-blur-[2.5px]`}
    >
      <div
        className={`${variants[variant]} flex items-center justify-center px-3 py-2 font-pally text-[10px] sm:text-[14px]`}
      >
        {icon && <div className="mr-2">{icon}</div>}
        {children}
      </div>
    </div>
  )
}

export default Badge
